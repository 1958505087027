// This page is portfolio page
import React from "react"
import { graphql, Link } from "gatsby"
import Footer from "../components/footer"
import ScrollToTopButton from "../components/ScrollToTopButton"
import { ThemeProvider } from '../components/themeContext'
import Seo from "../components/seo"

export default function Home({ data }) {

  return (
    // <ThemeProvider>
    <div>
    <Seo title={"Home"}></Seo>
      <div>
        <ScrollToTopButton></ScrollToTopButton>
        <div className="container flex flex-col mx-auto w-screen h-screen">
          <div className="contaienr flex flex-col mx-auto my-auto items-center justify-center font-sans">
            <h1 className="text-white border-2 rounded-lg md:border-4 mb-4 bg-gray-400 bg-opacity-40 p-6 border-white z-50 text-4xl xs:text-6xl">Wei Chen Huang</h1>
            <div className="contaienr flex flex-row mx-auto my-auto items-center">
              <a href="#aboutme" className="text-white border-opacity-0 hover:border-opacity-100 border-white z-50 mx-2 text-3xl xs:text-4xl my-2 border-b-2 md:border-b-4 transition hover:no-underline">About Me</a>
              <span className="text-white z-50 mx-2 text-3xl xs:text-4xl my-2">·</span>
              <a href="#select-projects" className="text-white border-opacity-0 hover:border-opacity-100 border-white z-50 mx-2 text-3xl xs:text-4xl my-2 border-b-2 md:border-b-4 transition hover:no-underline">Portfolio</a>
            </div>
          </div>
          <div className="w-screen h-screen left-0 top-0 absolute select-none bg-gray-900">
            <img className="opacity-40 m-0 object-cover w-screen h-screen pointer-events-none" alt="temple cover" src={data.coverImage.publicURL}/>
          </div>
        </div>
        
        <div>
          <div className="container relative mx-auto px-5 pt-16 items-center flex flex-col">
            <div id="aboutme" className="font-bold container items-start">
              <h2>About Me</h2>
            </div>
          
            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-2 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">The University of Texas at Austin (UT Austin)</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">M.S. in Software Engineering and Systems of Electrical and Computer Engineering</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">August 2024 - May 2026 (Expected) · Austin, TX</p>
                <div className="mt-4">
                  <b>Courses Taken</b>
                  <p>
                    Computer Architecture, Software Design Labs, Data Wrangling
                  </p>
                </div>
                <div className="container flex flex-col sm:flex-row justify-start mt-4 my-2 space-y-2 sm:space-y-0 sm:space-x-2 text-sm">
                  <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1bM8gDL7efOke-O_XFJBdPVQqW01iV_Ga/view?usp=sharing" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Résumé</button>
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/huang-weichen/" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">LinkedIn</button>
                  </a>
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-2 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">National Tsing Hua University (NTHU)</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">B.S. in Electrical Engineering and Computer Science</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">September 2019 - June 2023 · Hsinchu, Taiwan</p>
                <div className="mt-4">
                  <b>GPA</b> 3.97 / 4.0 <br/>
                  <b>Computer Science Relavant Courses</b>
                  <p>
                    Data Structure(A+), Computer Network(A+), Computer Architecture(A+), Software Studio(A+), Operating System(A+), Algorithm(A), Machine Learning(A), Computer System Administration(A), Database(A), Computer Graphic(A+), Parallel Programing(A-), Network Security(A+)
                  </p>
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row">
              <div className="container sm:w-2/5 flex flex-col sm:flex-row rounded-lg shadow-xl my-2 sm:mr-2 bg-white dark:bg-gray-600"> 
                <div className="container w-full py-5 px-8 relative">
                  <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Honors</h4>
                  <div className="mt-4">
                    <ul>
                      <li className="mt-2">College of EECS Outstanding Student Excellence Scholarship</li>
                      <li className="mt-2">1st Runner Up, 2022 TSMC x Microsoft Careerhack Competition</li>
                      <li className="mt-2">2nd Place, 4th APAC HPC-AI Competition</li>
                      <li className="mt-2">Houde Admission Scholarship</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container sm:w-3/5 flex flex-col sm:flex-row rounded-lg shadow-xl my-2 sm:ml-2 bg-white dark:bg-gray-600"> 
                <div className="container w-full py-5 px-8 relative">
                  <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Skills</h4>
                  <div className="mt-4">
                    <ul>
                      <li className="mt-2"><b>Programming</b><br/>JavaScript, TypeScript, HTML/CSS, Python, SQL, C/C++, C#, Java</li>
                      <li className="mt-2"><b>Web Framework</b><br/>React.js, Vue.js, Backbone.js, Node.js, Flask, FastAPI, MySQL, PostgreSQL, mongoDB</li>
                      <li className="mt-2"><b>Tools</b><br/>GCP, Azure, AWS, GNU/Linux, Git, Docker, Selenium/Webdriver, Figma</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="">
          <div className="container relative mx-auto px-5 pt-8 items-center flex flex-col">
            <div className="font-bold container items-start">
              <h2>Experience</h2>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Work</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Appier</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Full-Stack Engineering Intern, Professional Services Team</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">February 2024 - July 2024 · Taipei, Taiwan</p>
                <div className="mt-4">
                  <ul>
                    <li>Reduced over <b>90%</b> of database access with in-memory product inventory cache written in Python</li>
                    <li>Decreased file size by <b>26%</b> and network transactions by over <b>50%</b> by consolidating two ETL workflows (<b>2M+</b> orders, <b>500K+</b> members) into a single Python pipeline on Google Cloud Run</li>
                    <li>Developed new features on Line App with Line Frontend Framework, React for the UI, and Node.js with MySQL for backend API services to enhance B2C interaction</li>
                    <li>Conducted an early research and demoed of AI virtual customer service with text-to-video models in Vue.js</li>
                    <li>Enhanced the user experience of a Generative AI solution using React, resulting in <b>1st</b> place at an internal competition</li>
                    <li>Created an automatic image carousel display component using Splide.JS that integrates AIQUA scenario-id data flow</li>
                  </ul>
                </div>
              </div>
            </div>
          
            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Work</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Microsoft</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Software Engineering Intern, Bing Ads</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">July 2022 - June 2023 · Taipei, Taiwan</p>
                <div className="mt-4">
                  <ul>
                    <li>Increased CTR over <b>30%</b> by developing <a href="#microsoft-llm">LLM Recommendation Search Ad Creation UX</a> in React.js</li>
                    <li>Reduce request time by <b>40%</b> with async rendering in Image Recommendation by React.js and Backbone.js</li>
                    <li>Implemented an internal bulk upload page in React.js, supporting <b>1000+</b> records, reducing original manual efforts</li>
                    <li>Migrated customer survey platform to One Customer Voice platform, aligning with internal guidelines</li>
                    <li>Developed <b>10+</b> new features on the Recommendation Page using React.js, Backbone.js, TypeScript, C#, and CSS</li>
                    <li>Enhanced site reliability with <b>30+</b> Selenium/WebDriver based End-to-End and Unit tests in C# and JavaScript</li>
                    <li>Conducted early research on analyzing customer feedback with ChatGPT for ad recommendation in Python</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div id="research-experience" className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Research</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">National Tsing Hua University</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Intelligent Data Engineering and Applications Lab</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">Prof. Yi-Shin Chen · February 2022 - Octobor 2022 · Hsinchu, Taiwan</p>
                <div className="mt-4">
                  <p className="font-bold">Legal Judgment Prediction - Porting the TopJudge Framework to Taiwanese Judicial Decision Data</p>
                  <ul>
                    <li>Proved transplanting possibility by porting existing Chinese prediction models to Taiwanese court documents</li>
                    <li>Created a pipeline to process untamed Taiwanese legal judgment documents into structured data with Python</li>
                  </ul>
                </div>
                <div className="container flex flex-col sm:flex-row justify-start mt-4 my-2 space-y-2 sm:space-y-0 sm:space-x-2 text-sm">
                  <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/193FwhPSzGZApKACAWkY0U_Cis9AcsXiH/view?usp=sharing" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Poster (Chinese Only)</button>
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1wonECovPbAYXdEdDhNXq13l3VKxrBvM3/view?usp=sharing" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Slides</button>
                  </a>
                  <Link to={'portfolio/research-experience'} className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Learn More</button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Teaching</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Department of Computer Science, National Tsing Hua University</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Teaching Assistant of Introduction to Programming I & II</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">Prof. Shun-Ren Yang · January 2022 - January 2023 · Hsinchu, Taiwan</p>
                <div className="mt-4">
                  <ul>
                    <li>Managed a 5-TA team, efficiently delegating duties to ensure classroom assistance of 120+ students</li>
                    <li>Designed and evaluated 8+ assignments, weekly C/C++ online judge, exams, projects of Allegro5 and OOP</li>
                    <li>Conducted weekly office hours, offering personalized guidance and providing a collaborative learning environment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container relative mx-auto px-5 pt-8 items-center flex flex-col">
            <div id="select-projects" className="font-bold container items-start">
              <h2>Selected Project</h2>
            </div>

            <div id="appier-project" className="container flex flex-col rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container py-5 px-8 relative">
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Projects in Appier</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Appier, Professional Service Team</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">March 2024 - June 2024 · Taipei, Taiwan</p>
                <p className="sm:text-justify mt-4">
                  At Appier, I contributed to several innovative projects that enhanced user experience and showcased the power of AI in e-commerce.
                  One of the key features I worked on was an image carousel, designed to provide users with an engaging visual experience while browsing products.
                  Additionally, I was involved in the AI fitting UX redesign for the e-commerce shop, where we aimed to create a seamless and intuitive interface for customers even on mobile devices.
                  I also participated in a proof of concept for a text-to-video AI virtual customer service solution, which demonstrated how AI can facilitate real-time interactions and improve customer engagement.
                  These projects not only enriched my technical skills but also deepened my understanding of user-centered design in the rapidly evolving field of artificial intelligence.
                </p>
                <div className="container rounded-lg flex flex-col sm:flex-row w-full justify-center py-4 overflow-hidden">
                  <img className="shadow-none my-1 sm:mr-1 sm:w-1/5 rounded-md overflow-hidden" src="/assets/portfolio/appier/project1.gif"></img>
                  <img className="shadow-none my-1 sm:mx-1 sm:w-1/5 rounded-md overflow-hidden" src="/assets/portfolio/appier/project2.png"></img>
                  <img className="shadow-none my-1 sm:ml-1 sm:w-1/5 rounded-md overflow-hidden" src="/assets/portfolio/appier/project3.jpg"></img>
                </div>
              </div>
            </div>

            <div id="microsoft-llm" className="container flex flex-col rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container py-5 px-8 relative">
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">LLM Recommendation Search Ad Creation UX</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Microsoft, Bing Ads</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">February 2023 - May 2023 · Taipei, Taiwan</p>
                <p className="sm:text-justify mt-4">
                  During my internship at Microsoft, I had the opportunity to contribute to the development of the LLM Recommendation Search Ad Creation UX.
                  This innovative project aimed to enhance the user experience by leveraging advanced machine learning techniques to improve the relevance and effectiveness of online advertising.
                  My work involved collaborating with cross-functional teams to develop the UX and keep iterating on the latest design from designers, ensuring that the final product was not only functional but also intuitive.
                  The project gained attention in the tech community, highlighted by a newsroom article and a YouTube video clip, which detail the impact of our efforts on ad creation workflows.
                  This experience solidified my passion for user-centered design of developing a good user experience and demonstrated the potential of integrating AI in transforming digital interactions.
                  We shipped this features from POC within 1 quater.
                </p>
                <div className="container flex flex-col sm:flex-row justify-start mt-4 my-2 space-y-2 sm:space-y-0 sm:space-x-2 text-sm">
                  <a target="_blank" rel="noreferrer" href="https://about.ads.microsoft.com/en-ca/blog/post/july-2023/predictive-targeting-and-other-product-updates-for-july" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Newsroom Article</button>
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://youtu.be/nAocNujAs5U?si=AedHK1j-PwXHIFqH&t=1180" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Watch Real User Experience: Surfside PPC YouTube Clip</button>
                  </a>
                </div>
                <div className="container rounded-lg flex flex-col sm:flex-row w-full justify-center py-4 overflow-hidden">
                  <img className="shadow-none border border-black my-1 w-full sm:mr-1 sm:w-1/2 rounded-md overflow-hidden" src="/assets/portfolio/microsoft/screenshots1.png"></img>
                  <img className="shadow-none border border-black my-1 w-full sm:ml-1 sm:w-1/2 rounded-md overflow-hidden" src="/assets/portfolio/microsoft/screenshots2.png"></img>
                </div>
              </div>
            </div>
          
            <div className="container flex flex-col rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container py-5 px-8 relative">
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">GitoDo</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Software Studio Course Project</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">March 2021 - June 2021 · Hsinchu, Taiwan</p>
                <p className="sm:text-justify mt-4">
                  GitoDo introduces a new approach to task management, drawing inspiration from Git's branching system.
                  Traditional to-do apps fall short on collaboration and branching capabilities. 
                  GitoDo empowers users to create multiple main repositories for diverse tasks, enabling seamless collaboration within study groups or shared objectives.
                  This project earned accolades for its novelty and design. My multifaceted role spanned UI/UX design, front-end development, conflict resolution, and leadership. 
                </p>
                <div className="container flex flex-col sm:flex-row justify-start mt-4 my-2 space-y-2 sm:space-y-0 sm:space-x-2 text-sm">
                  <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1agDmIeMj5g9hvpqSxIshCyFU2BWkzm5i/view?usp=sharing" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Slides</button>
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=PavkGlCeCkg" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Video (Chinese Only)</button>
                  </a>
                  <Link to={'portfolio/gitodo'} className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Learn More</button>
                  </Link>
                </div>
                <div className="container rounded-lg flex flex-col sm:flex-row w-full justify-center py-4 overflow-hidden">
                  <img className="shadow-none my-1 w-full sm:mr-1 sm:w-1/2 rounded-md overflow-hidden" src="/assets/portfolio/gitodo/demo-branch.png"></img>
                  <img className="shadow-none my-1 w-full sm:ml-1 sm:w-1/2 rounded-md overflow-hidden" src="/assets/portfolio/gitodo/demo-task.png"></img>
                </div>
              </div>
            </div>
          </div>

          <div className="container relative mx-auto px-5 pt-8 items-center flex flex-col">
            <div id="competitions" className="font-bold container items-start">
              <h2>Competitions</h2>
            </div>
          
            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Domestic</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">2022 TSMC x Microsoft CareerHack Competition</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">1st Runner Up (Against 40+ teams from Taiwan)</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">January 2022 · Taiwan</p>
                <p className="sm:text-justify mt-4">
                  I participated in the CareerHack, a hackathon jointly hosted by TSMC and Microsoft Taiwan, where the challenge was to harness machine learning and MLOps for energy-efficient data center management.
                  Leading a team of four, my role was pivotal as we focused on optimizing data center cooling for eco-friendly computing. 
                  Our innovative concept involved leveraging the natural cool sea currents of the Taiwan Strait in conjunction with Azure Cloud Computing.
                  We designed a system that used water pumping to facilitate heat exchange between the sea's chilly waters and the heat-intensive liquids in the data center, all while dynamically controlling the process with MLOps.
                  We rigorously tested our hypotheses through simulations, demonstrating the feasibility of our approach and our commitment to sustainable data center solutions.
                </p>
                <div className="container flex flex-row justify-start mt-4 my-2 space-x-2 text-sm">
                  <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1HEi2yArNzgfI-bAkHlmf46CpgbueNxQu/view?usp=sharing" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Slides</button>
                  </a>
                </div>
                <div className="container rounded-lg flex flex-col sm:flex-row w-full justify-center py-4 overflow-hidden">
                  <img className="shadow-none border border-black my-1 w-full sm:mr-1 sm:w-1/2 rounded-md overflow-hidden" src="/assets/portfolio/careerhack/flow.png"></img>
                  <img className="shadow-none border border-black my-1 w-full sm:ml-1 sm:w-1/2 rounded-md overflow-hidden" src="/assets/portfolio/careerhack/presentation-15.png"></img>
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">International</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">4th APAC HPC-AI Competition</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">2nd Place (Against 35+ student teams from APAC region)</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">June 2021 - November 2021 · Remote</p>
                <div className="mt-4">
                  The 4th APAC HPC-AI Competition proved to be a formidable test of computing prowess,
                  challenging student teams across the APAC region to demonstrate their expertise in two distinct domains: GROMACS and DLRM.
                  DLRM, or Deep Learning Recommendation Model, emerged as a particularly intricate task, involving the implementation of the demanding MLPerf benchmark. 
                  The author and their teammate undertook this challenge with dedication, embarking on a journey that encompassed exhaustive research,
                  painstaking analysis of features, and the arduous task of reproducing the complex DLRM model.
                  In the end, their efforts culminated in a remarkable second place award, underscoring the significant growth and
                  expertise gained on the path toward becoming accomplished software developers
                </div>
                <div className="container flex flex-row justify-start mt-4 my-2 space-x-2 text-sm">
                  <Link to={'portfolio/hpcai'} className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="container relative mx-auto px-5 pt-8 items-center flex flex-col">
            <div id="extracurricular" className="font-bold container items-start">
              <h2>Extracurricular Activities</h2>
            </div>
          
            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Club</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Tainan Area High School Alumni Association</h4>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">National Tsing Hua University</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">September 2019 - September 2021 · Officer of Event Planning Team in 2020 · Hsinchu, Taiwan</p>
                <div className="sm:text-justify mt-4">
                  During my tenure as a leader in the Event Planning Department of the National Tsing Hua University Tainan Area High School Alumni Association,
                  I had the privilege of orchestrating numerous initiatives aimed at fostering connections among students from the Tainan Area attending NTHU.
                  These events, including parties with other area alumni clubs (such as the NCTU Kaohsiung Area High School Alumni Association and NCTU Tainan Area High School Alumni Association)
                  and "NanNight," featuring performances including plays and dancing, served as a bridge, uniting individuals from diverse backgrounds under the shared banner of our alma mater.
                  In my role, I not only managed, but also spearheaded several impactful gatherings and functions.
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Volunteer</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">NTHU Electrical Engineering Education Camp</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">July 2020 · Hsinchu, Taiwan</p>
                <div className="sm:text-justify mt-4">
                  In the NTHU EE Camp, being a member of the Event Planning Department, I designed and implemented several events for to help 100+
                  participants get to know each other, while I cooperated with other members and other departments to teach high school participants
                  to learn basic electrical engineering knowledge and share our viewpoints of studying in college of EECS in NTHU.
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">Volunteer</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Homecoming Education Service Camp</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">January 2020 · Tainan, Taiwan</p>
                <div className="sm:text-justify mt-4">
                During my participation in the Homecoming Education Service Camp, I assumed a crucial role within the Event Planning Department. 
                In this capacity, my responsibilities extended beyond the ordinary as I took charge of performing plays that aimed to educate elementary students on various scientific concepts.
                Additionally, I was entrusted with the design and implementation of several educational games, strategically crafted to enable children to learn while having a blast.
                Despite the camp's duration being a mere four days, the preparation phase was an extensive three-month journey. 
                <br/><br/>Throughout this period, our team invested substantial time and effort into brainstorming and refining the plots for our educational plays.
                We meticulously designed each game, ensuring they were not only entertaining but also effective in conveying scientific knowledge.
                Every aspect of our planning was carefully considered to guarantee a seamless and immersive educational experience.
                </div>
              </div>
            </div>

            <div className="container flex flex-col sm:flex-row rounded-lg shadow-xl my-4 bg-white dark:bg-gray-600"> 
              <div className="container w-full py-5 px-8 relative">
                <div className="font-bold text-green-600 md:absolute md:right-8 md:top-6">General Education Class</div>
                <h4 className="font-bold block text-gray-500 dark:text-gray-300 text-md">Oil Painting</h4>
                <p className="block text-gray-500 dark:text-gray-300 text-md mt-2">July 2022 - June 2023 · Taipei, Taiwan</p>
                <div className="sm:text-justify mt-4">
                This course was designed to sharpen my aesthetic sensibilities and deepen my understanding of design principles.
                Focused on the fundamentals of sketching, the class introduced me to the intricacies of color analysis in oil painting.
                While oil painting and UI/UX design may seem disparate, this experience bridged the gap between them.
                Armed with knowledge in Human-Computer Interaction (HCI), I began to recognize connections between website design and the principles of color, composition, and aesthetics I had learned.
                This newfound synergy allowed me to approach UI/UX design holistically, emphasizing the importance of aesthetics in user experience.
                As the course progressed, I applied the analytical skills I developed in oil painting to dissect compositions and layouts, aligning with the principles of composition analysis.
                This skill became a valuable asset in designing visually harmonious and user-centric interfaces.
                </div>
                <div className="container flex flex-col sm:flex-row justify-start mt-4 my-2 space-y-2 sm:space-y-0 sm:space-x-2 text-sm">
                  <a href="https://drive.google.com/drive/folders/1H8_6pcjILf6KKUKpgc_BIEBjEn-rOVKr?usp=share_link" target="_blank" rel="noreferrer" className="font-semibold">
                    <button className="dark:text-white p-1 px-3 rounded border border-blue-500 hover:bg-blue-100">Paintings (Full Resolution)</button>
                  </a>
                </div>
                <div className="container rounded-lg relative flex sm:flex-row flex-col w-full justify-center sm:px-2 py-4">
                  <img className="shadow-none my-1 w-full sm:mr-1 sm:w-1/3 rounded-md" src="/assets/portfolio/oilpainting/painting1.jpg"></img>
                  <img className="shadow-none my-1 w-full sm:mx-1 sm:w-1/3 rounded-md" src="/assets/portfolio/oilpainting/painting2.jpg"></img>
                  <img className="shadow-none my-1 w-full sm:ml-1 sm:w-1/3 rounded-md" src="/assets/portfolio/oilpainting/painting3.jpg"></img>
                </div>
              </div>
            </div>
          </div>

          <div className="h-20"></div>
          <Footer></Footer>
        </div>
      </div>
    </div>
    // </ThemeProvider>
  )
}

export const pageQuery = graphql `
query IndexQuery {
    site {
      siteMetadata {
        title 
        description
      }
    }
    coverImage: file(base: { eq: "portfolio-page.jpeg" }) {
      publicURL
    }
    gitodo1Image: file(base: { eq: "gitodo.png" }) {
      publicURL
    }
    gitodo2Image: file(base: { eq: "mockup.png" }) {
      publicURL
    }
    gitodo3Image: file(base: { eq: "prototype.png" }) {
      publicURL
    }
    latestPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { layout: { eq: "portfolio-post" } }
      }
      limit: 3
    ) {
      posts:nodes {
        excerpt(pruneLength: 80)
        id
        fields {
          slug
        }
        frontmatter {
          title
          layout
          date(formatString: "MMMM YYYY")
          category
          description
        }
      }
    }
  }
`
